<script lang="ts" setup>
const sessionStorageKey = 'apz_hideHomeLoginBanner';

const hideHomeLoginBanner = useSessionStorage(sessionStorageKey, 'false', { writeDefaults: false });

if (import.meta.server) {
  useServerHead({
    script: [
      {
        key: 'prehydrate-template-login-banner',
        innerHTML: `
            var isLoggedIn = false;
            var hideHomeLoginBanner = sessionStorage.getItem('${sessionStorageKey}');
            var authCookies = document.cookie.split('; ').find((row) => row.startsWith('auth._token.airpaz='));

            if (authCookies) {
              isLoggedIn = Boolean(authCookies.split('=')[1]); 
            }

            if (hideHomeLoginBanner || isLoggedIn) {
              document.querySelector('html').classList.add('hide-login-banner');
            }`.replace(/\s+/g, ' '),
        type: 'text/javascript',
      },
    ],
  });
}

const { jitsuEvent } = useTracker();

const onClickLoginButton = () => {
  jitsuEvent('user-click-button', {
    event_name: 'banner-membership',
    page_referrer: 'homepage',
  });

  navigateSignIn();

  onClose();
};

const onClose = () => {
  hideHomeLoginBanner.value = 'true';

  document.querySelector('.home-login-banner')?.classList.add('animate-out');

  setTimeout(() => {
    document.querySelector('html')?.classList.add('hide-login-banner');
  }, 300);
};
</script>

<template>
  <div class="home-login-banner p-20 rounded bg-white">
    <div class="flex items-center gap-x-15 mb-15 relative">
      <NuxtImg
        src="/img/illustration/v2/spot/member-deals.png"
        alt="hero deal"
        width="50"
        height="50"
        format="webp"
        loading="lazy"
        sizes="lg:100px"
        class="w-[50px] h-[50px]"
      />

      <div class="w-[195px]">
        <h2 class="font-bold mb-10">{{ $t('global.signinspecialdealstitle') }}</h2>

        <p class="text-small max-w-[160px]">
          {{ $t('global.signinspecialdealsdesc') }}
        </p>
      </div>

      <div
        class="absolute -top-5 -right-5 w-15 h-15 cursor-pointer"
        @click="onClose"
      >
        <ApzIcon
          icon="times"
          class="text-[12px]"
        />
      </div>
    </div>

    <ApzButton
      size="small"
      type="primary"
      class="whitespace-nowrap w-full !h-35"
      @click="onClickLoginButton"
    >
      {{ $t('global.signinorregisterhere') }}
    </ApzButton>
  </div>
</template>

<style scoped>
.hide-login-banner .home-login-banner {
  @apply hidden;
}

.home-login-banner {
  &.animate-out {
    @apply transform -translate-y-full duration-200 opacity-0;
  }
}
</style>
